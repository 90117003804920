import apiKey from './apiKey.js'
// export const login = data => apiKey.http.post('/login', data, {})
class ApiFetch {
    get(pampasCall, params={}) {
        return   apiKey.http.get(pampasCall, {params})
    }
    post(pampasCall, params) {
        return   apiKey.http.post(pampasCall, params,{})
    }
    put(pampasCall, params) {
        return   apiKey.http.put(pampasCall, params,{})
    }
    delete(pampasCall, params) {
        return   apiKey.http.delete(pampasCall, params,{})
    }
}
const apiFetch = new ApiFetch()
export default apiFetch