import axios from 'axios'
// import router from '../router/index'
import { redirect_registerurl, config } from "./config";
const appid = config.wxInfo.appId
let apiKey = {

}
apiKey.title = (title) => {
  title = title || ''
  window.document.title = title
}

//  创建axiox 实例
apiKey.http = axios.create({
  baseURL: '/api',
  timeout: 30000
})
// api
// http request 拦截器
apiKey.http.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      // const token = localStorage.Authorization
      config.headers.Authorization = `${token}`
    }
    return config
  },
  err => {
    return Promise.reject(err)
  })

// http response 拦截器
apiKey.http.interceptors.response.use(
  response => {
    if (response.data.code === 401) {
      //跳转到登陆页面
      localStorage.removeItem("token");
      localStorage.removeItem("openid");
      localStorage.removeItem("userInfo");
      // location.reload();
      // router.replace({
      //   path: '/register'
      // })
      
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_registerurl}&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect`
    }
    return response
  },
  error => {
    console.log(error.response, 'qingqiu')
    if (error.response) {
      switch (error.response.status) {
        case 401:
          //跳转到登陆页面
          
          localStorage.removeItem("token");
          localStorage.removeItem("openid");
          localStorage.removeItem("userInfo");
          // location.reload();
          // router.replace({
          //   path: '/register'
          // })
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_registerurl}&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect`
          break
      }
    }
    // console : Error: Request failed with status code 402
    return Promise.reject(error.response.message)
  })

export default apiKey