<template>
  <div id="app">
    <keep-alive :include="list">
      <router-view></router-view>
    </keep-alive>
    <van-overlay :show="loading" :custom-style="{ backgroundColor: 'transparent' }">
      <div class="wrapper">
        <div>
          <van-loading vertical type="spinner" color="#fff" size="24px">
            加载中...
          </van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["loading"]),
    list() {
      return [...this.$store.getters.keepAlive];
    },
  },
  data() {
    return {
    };
  },

  /* eslint-disable */
  created() {
    /*---调整安卓手机软键盘遮挡输入框问题---*/
    if (/Android/.test(navigator.appVersion)) {
      window.addEventListener("resize", this.fixAndroid(), false);
    }
    /**
     * 处理iOS 微信客户端6.7.4 键盘收起页面未下移bug
     */
    if (/iphone|ipod|ipad/i.test(navigator.appVersion)) {
      document.addEventListener(
        "blur",
        function (e) {
          ["input", "textarea"].includes(e.target.localName) &&
            setTimeout(function () {
              let scrollHeight =
                document.documentElement.scrollTop ||
                document.body.scrollTop ||
                0;
              window.scrollTo(0, Math.max(scrollHeight - 1, 0));
            }, 100);
        },
        true
      );
    }
    // this.initConf()
    console.log(this.$router.history._startLocation, "this.$route");
  },
  /* eslint-disable */
  mounted() { },
  methods: {
    //判断路由
    async showPage() {
      const { data } = await this.$apiFetch.get("/userinfo/userInfo");
      this.$store.commit("hideLoading");
      if (data.code.errcode == 0) {
        if (data.data.productSerial) {
          this.$router.replace({
            path: '/'
          })
        } else {

        }
        console.log(data);
        localStorage.setItem("userInfo", JSON.stringify(data.data));
      } else {
        return this.$Toast(data.description || data.code.errmessage);
      }
    },





    initConf() {
      this.$apiFetch.get("/mall/common/appId", { type: "h5" }).then((res) => {
        localStorage.setItem("defaultappId", res.data.data);
      });
    },
    fixAndroid() {
      if (
        document.activeElement.tagName == "INPUT" ||
        document.activeElement.tagName == "TEXTAREA"
      ) {
        window.setTimeout(function () {
          document.activeElement.scrollIntoViewIfNeeded();
        }, 300);
      }
    },
  },
  /* eslint-disable */
  beforeDestroy() {
    // 移除监听
    if (/Android/.test(navigator.appVersion)) {
      window.removeEventListener("resize", this.fixAndroid(), false);
    }
    if (/iphone|ipod|ipad/i.test(navigator.appVersion)) {
      document.removeEventListener(
        "blur",
        function (e) {
          ["input", "textarea"].includes(e.target.localName) &&
            setTimeout(function () {
              let scrollHeight =
                document.documentElement.scrollTop ||
                document.body.scrollTop ||
                0;
              window.scrollTo(0, Math.max(scrollHeight - 1, 0));
            }, 100);
        },
        true
      );
    }
  },
  /* eslint-disable */
};
</script>
<style lang="less">
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999999;
  font-size: 28px;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999999;
  font-size: 28px;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999999;
  font-size: 28px;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #999999;
  font-size: 28px;
}

html {
  height: 100%;
}

body {
  overflow-x: hidden;
  padding-bottom: calc(0px + constant(safe-area-inset-bottom));
  padding-bottom: calc(0px + env(safe-area-inset-bottom));
  height: 100%;
  white-space: normal;
  // word-break: break-all;
  word-break: normal;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  height: 100%;
}

/*css 初始化 */
html,
body,
ul,
li,
ol,
dl,
dd,
dt,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
legend,
img {
  margin: 0;
  padding: 0;
}

fieldset,
img,
input,
button,
textarea {
  border: none;
  padding: 0;
  margin: 0;
  outline-style: none;
}

ul,
ol {
  list-style: none;
}

input {
  padding-top: 0;
  padding-bottom: 0;
  font-family: "SimSun", "宋体";
}

select,
input {
  vertical-align: middle;
}

select,
input,
textarea {
  margin: 0;
}

textarea {
  resize: none;
}

/*  去掉图片低测默认的3像素空白缝隙*/

table {
  border-collapse: collapse;
}

body {
  font: 24px/150% Arial, Verdana, "\5b8b\4f53";
  font-family: -apple-system-font, sans-serif;
  color: #666;
  background: #fff;
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
  /*IE/7/6*/
}

a {
  color: #666;
  text-decoration: none;
}

/* a:hover {
    color: #f2ad00;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  text-decoration: none;
  font-weight: normal;
  font-size: 100%;
}

s,
i,
em {
  font-style: normal;
  text-decoration: none;
}

//
body,
div,
p {
  margin: 0;
  padding: 0;
}

button {
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
}

input {
  outline: none;
  border: none;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.bottomBtn {
  width: 690px;
  height: 88px;
  line-height: 88px;
  position: fixed;
  bottom: 20px;
  left: 30px;
  color: #f3dbc5;
  font-size: 30px;
  font-weight: 500;
  background: rgba(72, 68, 82, 1);
  border-radius: 10px;
}

.mt-40 {
  margin-top: 40px !important;
}

// 路由动画
.Router {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  transition: all 0.5s ease;
  top: 40px;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100% 0);
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 99999;

  div {
    height: 200px;
    width: 200px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
}

.wrap {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  img {
    width: 100%;
    height: 100%;
  }

  .close {
    width: 116px;
    height: 50px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 25px;
    position: absolute;
    right: 38px;
    top: 38px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 50px;
    text-align: center;
  }
}
</style>
