const Routes = [
  {
    path: "/",
    name: "Home",
    title: "首页",
    meta: {
      keepAlive: true,
    },
    component: (resolve) =>
      require(["@/pages/tabModule/Home.vue"], resolve),
  },
];
export default Routes;