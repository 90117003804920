import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    loading: false,
    keepAlive: [],
  },
  getters: {
    keepAlive: (state) => state.keepAlive || [],
  },
  mutations: {
    set_userInfo(state, payload) {
      localStorage.setItem("userInfo", JSON.stringify(payload));
      state.userInfo = payload;
    },
    showLoading(state) {
      state.loading = true;
    },
    hideLoading(state) {
      state.loading = false;
    },
    setKeepAlive(state, list) {
      state.keepAlive = list;
    },
    keepAliveAdd(state, name) {
      const list = [...state.keepAlive, name];
      state.keepAlive = list;
    },
    keepAliveRemove(state, name) {
      const list = state.keepAlive.filter((item) => item !== name);
      state.keepAlive = list;
    },

    setUserName(state, name) {
      state.userInfo.employeeName = name;
    },

    setUserheadImg(state, headimg) {
      state.userInfo.headImg = headimg;
    },

    //更新一下本地用户信息
    setUserinfo(state) {
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    }


  },
  actions: {
    keepAliveAdd(contet, name) {
      const list = [...contet.getters.keepAlive, name];
      contet.commit("setKeepAlive", list);
    },
    keepAliveRemove(contet, name) {
      const list = contet.getters.keepAlive.filter((item) => item !== name);
      contet.commit("setKeepAlive", list);
    },
  },
  modules: {}
})