import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vconsole from "vconsole";
import apiFetch from "./config/apiFetch";
import "vant/lib/index.css";
import {
  Popup,
  Swipe,
  SwipeItem,
  Picker,
  List,
  Radio,
  Icon,
  Tab,
  Tabs,
  Field,
  Cell,
  RadioGroup,
  Checkbox,
  Switch,
  Search,
  Rate,
  Uploader,
  Slider,
  Toast,
  Overlay,
  Loading,
  Area,
  Dialog,
  DatetimePicker,
  Button,
  Tag,
  CellGroup,
  Form,
  ActionSheet,
  DropdownMenu,
  DropdownItem,
  PullRefresh,
  SwipeCell,
  Card,
  TreeSelect,
  Badge,
  Image as VanImage
} from "vant";
Vue.use(Popup)
  .use(Swipe)
  .use(SwipeItem)
  .use(Picker)
  .use(List)
  .use(Radio)
  .use(Icon)
  .use(Tab)
  .use(Tabs)
  .use(Overlay)
  .use(Field)
  .use(Cell)
  .use(RadioGroup)
  .use(Checkbox)
  .use(Switch)
  .use(Search)
  .use(Rate)
  .use(Uploader)
  .use(Loading)
  .use(Slider)
  .use(Toast)
  .use(Area)
  .use(Dialog)
  .use(DatetimePicker)
  .use(Button)
  .use(Tag)
  .use(Form)
  .use(ActionSheet)
  .use(CellGroup)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(PullRefresh)
  .use(SwipeCell)
  .use(Card)
  .use(Badge)
  .use(VanImage)
  .use(TreeSelect);

Vue.config.productionTip = false;
Vue.prototype.$apiFetch = apiFetch;
new Vconsole();
Vue.prototype.$Toast = Toast;
// 设置浏览器标题
Vue.directive("title", {
  inserted: function (el) {
    document.title = el.dataset.title;
  },
});

(async () => {
  // const token = localStorage.getItem('token')
  // if(!token){
  //   const { data } = await apiFetch.get("/userinfo/userInfo");
  //     console.log(data);
  // }else{
  //   router.push({
  //     path: '/home'
  //   })
  // }
  // console.log(token)
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
})();